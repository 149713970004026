<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-form class="multi-col-validation">
      <v-card-text class="pt-5">
        <v-row>
          <v-col cols="12">
            <v-textarea
              v-model="user.user_type.type_usr_name"
              outlined
              rows="3"
              label="Acces"
            ></v-textarea>
          </v-col>

<!--          <v-col-->
<!--            cols="12"-->
<!--            md="6"-->
<!--          >-->
<!--            <v-text-field-->
<!--              v-model="user.staff."-->
<!--              outlined-->
<!--              dense-->
<!--              label="Birthday"-->
<!--            ></v-text-field>-->
<!--          </v-col>-->

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="user.staff.staff_contact"
              outlined
              dense
              label="Telephone"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="user.staff.staff_address"
              outlined
              dense
              label="Addresse"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <p class="text--primary mt-n3 mb-2">
              Civilite
            </p>
            <v-radio-group
              v-model="user.staff.staff_sexe"
              row
              class="mt-0"
              hide-details
            >
              <v-radio
                value="M."
                label="M."
              >
              </v-radio>
              <v-radio
                value="Mme"
                label="Mme"
              >
              </v-radio>
              <v-radio
                value="Mlle"
                label="Mlle"
              >
              </v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text>
        <v-btn
          color="primary"
          class="me-3 mt-3"
        >
          Modifier
        </v-btn>
        <v-btn
          outlined
          class="mt-3"
          color="secondary"
          type="reset"
        >
          Annuler
        </v-btn>
      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>

export default {
  props: {
    informationData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      user: {},
    }
  },
  mounted() {
    // console.log(localStorage.getItem('currentUser'))
    this.loadUser()
  },

  methods: {
    loadUser() {
      this.$axios.get(this.$endpoint.FindUser(JSON.parse(localStorage.getItem('currentUser')).id)).then(rp => {
        this.user = rp.data

        // console.log(this.user)
      })
    },
  },

}
</script>
